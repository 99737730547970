.idImageHeader
  display: block
  margin-left: auto
  margin-right: auto
  margin-top: 50px

.HeaderTextScId
  text-align: center !important
  font-family: Arial, Helvetica, sans-serif !important
  font-weight: bold !important
  font-size: 20px !important
  margin-top: 7px !important

.HeaderTextScId2
  text-align: center !important
  font-family: "Old English Text MT" !important
  font-size: 30px !important

.HeaderTextScId3
  text-align: center !important
  font-family: "Old English Text MT" !important
  font-size: 40px !important
  padding-bottom: 16px

.imageUploadedScId
  margin-left: 75px
  width: 20%
  margin-top: 10px

.underlineScId2
  margin-left: 60px
  margin-top: -10px

.personalInformation3
  margin-left: 80px !important
  font-family: Arial, Helvetica, sans-serif !important
  font-size: 12px !important
  margin-top: -20px !important

.informationSheetScId
  text-align: center
  margin-top: -50px  !important
  font-family: Arial, Helvetica, sans-serif !important
  font-weight: bold !important
  

.personalInformation
  margin-left: 50px !important
  font-family: Arial, Helvetica, sans-serif !important
  font-weight: bold !important
  font-size: 15px !important

.tableMarginStyle
  margin-left: 50px
  width: 90%

td.firstDataSupremeCourt 
  width: 15%

.informationScId 
  font-family: Arial, Helvetica, sans-serif
  font-size: 15px

td.informationSupremeCourt 
  background-color: #D3D3D3
  width: 35%

.inputedInformationScId 
  font-family: Arial, Helvetica, sans-serif
  font-size: 15px
  height: .5px

td.middleSpacingSupremeCourt 
  width: 2%

.informationScId2 
  font-family: Arial, Helvetica, sans-serif
  font-size: 15px
  width: 100px

td.informationSupremeCourt2
  background-color: #D3D3D3
  width: 40%

td.informationSupremeCourt5
  width: 33%
  background-color: #D3D3D3

td.firstDataSupremeCourt3 
  width: 35%

td.informationSupremeCourt4 
  width: 65%
  background-color: #D3D3D3

.hideSCiD 
    visibility: hidden
    display: inline-block
    

.uploadedSignatureScId 
  width: 20%
  display: block
  margin-left: auto
  margin-right: auto

.underlineScId 
  text-align: center

.personalInformation2 
  text-align: center
  font-family: Arial, Helvetica, sans-serif !important
  font-weight: bold !important
  font-size: 15px !important
  margin-top: -20px !important
