img.scRevisedHeader
  display: block !important
  margin-left: auto !important
  margin-right: auto !important
  height: 100px !important
  margin-bottom: 5px !important
  margin-top:20px !important
p.firstHeaderText
  font-family: "Old English Text MT" !important
  font-size: 18px !important
  display: block !important
  text-align: center !important
p.secondHeaderText
  font-family: "Old English Text MT" !important
  font-size: 22px !important
  display: block !important
  text-align: center !important
  margin-top: -15px !important
  font-weight: bold !important
  text-shadow: 1px 2px #d9d5d5
img.secondScRevisedHeader
  display: block !important
  margin-left: auto !important
  margin-right: auto !important
  height: 80px !important
  margin-bottom: 10px !important
  margin-top: 0px !important
  width: 300px !important
p.rollNumberText
  font-size: 4px !important
  font-family: "Book-Antiqua" !important
  color: white !important
  margin-top: -72px !important
  padding-left: 5px !important
  margin-bottom: 55px !important
  text-align: center !important
  display: block !important
p.secondTitle
  font-family: "Old English Text MT" !important
  font-size: 20px !important
  text-align: center !important
  line-height: 175% !important
p.firstCertificateBody
  font-family: "Edwardian Script ITC" !important
  font-style: italic !important
  font-size: 23px !important
  margin-left: 50px !important
  margin-right: 50px !important
  margin-top: -10px !important
  text-align: justify !important
p.firstCertificateBody1
  font-family: "Edwardian Script ITC" !important
  font-style: italic !important
  font-size: 23px !important
  margin-left: 50px !important
  margin-right: 50px !important
  margin-top: -10px !important
.span1
  font-family: "Edwardian Script ITC" !important
  font-style: italic !important
  font-size: 26px !important
  margin-left: 35px !important
  margin-right: 50px !important
  margin-top: -10px !important
  line-height: 100% !important
.span2
  font-family: "Edwardian Script ITC" !important
  font-style: italic !important
  font-size: 26px !important
  margin-top: -10px !important
.span3
  font-family: "Edwardian Script ITC" !important
  font-style: italic !important
  font-size: 26px !important
  margin-left: 35px !important
  margin-right: 50px !important
  margin-top: -10px !important
  line-height: 100% !important
.span4
  font-family: "Edwardian Script ITC" !important
  font-style: italic !important
  font-size: 26px !important
  margin-left: 50px !important
  margin-top: -10px !important
  line-height: 125% !important
.span5
  font-family: "Edwardian Script ITC" !important
  font-style: italic !important
  font-size: 26px !important
  margin-left: 35px !important
  margin-right: 50px !important
  margin-top: -10px !important
  line-height: 100% !important
p.firstCertificateBody2
  font-family: "Edwardian Script ITC" !important
  font-style: italic !important
  font-size: 23px !important
  margin-left: 60px !important
  margin-right: 50px !important
  margin-top: -10px !important
  line-height: 50% !important
  font-size: 25px !important
.scEditCertSigDiv
  width: 100% !important
//   padding-left: 60%
.clerkSigDiv
  text-align: right !important
  margin-right: 150px !important
  height: 65px !important
  margin-bottom: 5px !important 
.qrCodeDiv
  text-align: right !important
  margin-right: 45px !important
  height: 65px !important
  margin-bottom: 10px !important  
.clerkSigDivEdit
  text-align: right !important
  // margin-right: 0 !important
  // margin-bottom: 26px !important
.editClerkSig
  margin-right: 14px !important
.clerkSig
  width: 100px !important
  height: 56px !important
  margin-top: -10px !important
p.firstCertificateName
  font-family: "Candara" !important
  font-weight: bold !important
  font-size: 12px !important
  // text-align: right !important
  margin-right: 150px !important
  display: block !important
  // margin-top: -20px !important
  text-transform: uppercase !important
.editCertAlign
  text-align: center !important
  margin-right: 0 !important
  padding-right: 0 !important
p.firstCertificatePosition
  font-family: "Book-Antiqua" !important
  font-weight: 500 !important
  font-size: 8px !important
  // text-align: right !important
  // margin-right: 150px !important
  // padding-right: 50px !important
  // margin-top: -15px !important
span.certificateDate
  font-size: 23px !important
  font-family: 'Edwardian Script ITC' !important
  font-style: italic !important
span.firstCertificateData
  font-size: 26px !important
  font-family: "Old English Text MT" !important

.pdf-canvas
  .qrcode
      height: 72px !important
      width: 72px !important
      margin-top: -30px !important

  .signature
      text-align: right
      margin-top: 10px
      margin-right: 160px
      display: flex
      flex-direction: column
      justify-content: center
      align-items: flex-end

  .signature-content
      display: flex
      flex-direction: column
      align-items: center
      text-align: center

p.clerkName
  font-family: "Candara" !important
  font-weight: bold !important
  font-size: 12px !important
  // text-align: right !important
  // margin-right: 150px !important
  // display: block !important
  margin-top: -3px !important
  text-transform: uppercase !important

p.clerkOfCourt
  font-family: "Book-Antiqua" !important
  font-weight: 500 !important
  font-size: 8px !important
  // text-align: right !important
  // margin-right: 150px !important
  // padding-right: 50px !important
  margin-top: -15px !important

// div.img-signature-container
// overflow: hidden

// div.img-signature-container img[alt]
// margin-left: -16px
// text-indent: 16px

.image-container
  width: 100px
  height: 56px
  overflow: hidden

.clerkSig1
  max-width: 100%
  max-height: 100%
  object-fit: contain