.HeaderTextTravelAuth
  text-align: center
  font-family: "Old English Text MT" !important
  font-size: 30px !important
  margin-top: -30px !important
  font-weight: bold !important


.HeaderTextTravelAuth2 
  text-align: center
  font-family: "Old English Text MT" !important
  font-size: 30px !important
  margin-top: -8px !important
  font-weight: bold !important

.dateTravelAuthData
  font-size: 20px
  margin-top: -46px
  margin-left: 470px
  font-family: 'Times New Roman', Times, serif

.indentedParagraph 
  text-align: left
  font-size: 22px
  margin-left: 200px
  font-family: 'Times New Roman', Times, serif
  width: 70%
  margin-top: 30px


.bodyTravelAuthSecond 
  font-size: 18px
  margin-left: 120px
  font-family: 'Times New Roman', Times, serif
  width: 65%


.scTravelAuthContent 
  padding: 32px 60px 0 120px
  min-height: 845px
  display: flex
  flex-direction: column

.travelAuthOnePage
  min-height: 1360px!important
  .scTravelAuthContent
      min-height: 1360px!important

.travelAuth
  width: 750px!important
  min-height: 1200px!important
  
.travelPageSize 
  width: 100%
  height: 18.5cm
  border:1px solid red


.travelAuthDefaultPage 
  text-align: justify
  font-size: 18px
  font-family: "Times New Roman", Times, serif
  padding: 0px 64px 0px
  list-style-type: disc
  white-space: pre


.travelAuthPageBreaker 
  position: absolute
  font-size: 18px
  font-family: "Times New Roman", Times, serif
  // top: 1200px
  top: 1031px
  left: 0
  text-align: left
  // padding-left: 72px
  padding-left: 300px
  width: 100%


.scroolVertical 
  margin-top: -15px
  padding-right:15px
  padding-top: 20px
  width: 100%
  height: 350px
  overflow: auto
  text-align: justify


.bodyTravelAuth 
  text-align: justify
  font-size: 18px
  font-family: "Times New Roman", Times, serif
  margin-top: -8px
  text-indent: 90px


.dateTravelAuth 
  font-size: 18px
  margin-top: 20px
  text-align: right
  font-family: 'Times New Roman', Times, serif


.travelAuthCenterText 
  text-align: center
  font-size: 24px
  font-weight: bold
  font-family: 'Times New Roman', Times, serif
  margin-top: 28px


.inputDataDynamicTravelAuth
  margin-top: -27px !important 
  margin-left: 64px !important 
  // padding-left: 56px
  font-size: 18px
  list-style-type: disc
  white-space: pre
  font-family: 'Times New Roman', Times, serif


.cheifJusticeTravelAuth 
  font-family: 'Times New Roman', Times, serif
  font-size: 18px
  text-align: center
  margin-left: 320px
  margin-top: 80px
  // padding-bottom: 80px

.idImageHeader 
  display: block
  margin-left: auto
  margin-right: auto
  margin-top: 50px
  width: 100px
  height: 100px

.HeaderRepublicOfPhil
  text-align: center
  font-family: "Old English Text MT" !important
  font-size: 26px !important
  margin-top: 7px !important

.table100
  width: 100%
  border-collapse: collapse

.BottomSignatoryDiv
  width:100%
  display: flex
  justify-content: right

.bottomSignature
  margin-top: 100px
  border-top:1px solid #000
  width: 40%

.scroolVertical 
  margin-top: -15px
  padding-right:15px
  padding-top: 20px
  width: 100%
  height: 350px
  overflow: auto
  text-align: justify
