
    
    /* You can add global styles to this file, and also import other style files */
@font-face 
    font-family: 'Book Antiqua'
    src: url("./assets/fonts/Book Antiqua Font.ttf") format("truetype")


@font-face 
    font-family: 'Candara'
    src: url("./assets/fonts/Candara Font.ttf") format("truetype")


@font-face 
    font-family: 'Old English Text MT'
    src: url("./assets/fonts/Old English Text MT.ttf") format("truetype")


@font-face 
    font-family: 'Edwardian Script ITC'
    src: url("./assets/fonts/Edwardian Script ITC.ttf") format("truetype")



@import './assets/form-styles/travel-authority'
@import './assets/form-styles/sc-id'
@import './assets/form-styles/certificates'

$primary-color: #1181b2

$secondary-color: #212221

$accent-color-500: #ddedf4
$accent-color-600: #f2e6d9

$primary-text-color: #D0BE49
$success-color: #23AC00

$dms-sc-purple: #4B2A47

$black: #4b4b4b
$dms-black: #212221

$gradient: linear-gradient(180deg, #4B2A47 0%, #212221 100%)

$gray-color-100: #ffffff
$gray-color-200: #f5f5f5
$gray-color-300: #ebebeb
$gray-color-400: #cccccc
$gray-color-500: #a3a3a3
$gray-color-600: #8f8f8f
$gray-color-700: #7a7a7a
$gray-color-800: #666666
$gray-color-900: #525252

button
    cursor: pointer

.mat-progress-spinner circle, .mat-spinner circle
    stroke: #4B2A47 !important

.flex-center
    align-items: center
    justify-content: center

.flex-self-left
    justify-self: flex-start
    align-self: flex-start
/*** TO USE MIXIN **
// Import this sass
// to call @include for-size(phone-only) {    font-size: 1rem; }
// must be inside a parent class
// Example
// .header 
//      font-size: 2rem
//      @include for-size(phone-only) {    font-size: 1rem; }

@mixin for-size($size)
    @if $size == phone-only
        @media (max-width: 599px)
            @content
    @else if $size == iphone14
        @media only screen and (min-device-width: 390px) and (max-device-width: 430px) and (orientation:portrait)
            @content
    @else if $size == ipad
        @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait)
            @content
    @else if $size == tablet
        @media (min-width: 896px) and (max-width: 961px)
            @content
    @else if $size == big-tablet
        @media (min-width: 962px) and (max-width: 1023px)
            @content
    @else if $size == big-tablet-up
        @media (min-width: 1024px) and (max-width: 1279px)
            @content
    @else if $size == desktop
        @media (min-width: 1280px) and (max-width: 1365px)
            @content
    @else if $size == desktop-up
        @media (min-width: 1366px) and (max-width: 1439px)
            @content
    @else if $size == big-desktop
        @media (min-width: 1440px)
            @content
    @else if $size == big-desktop-up
        @media (min-width: 1920px)
            @content
    @else if $size == tablet-down
        @media (max-width: 1023px)
            @content
    @else if $size == ipad-down
        @media (max-width: 900px)
            @content
    @else if $size == mac-default
        @media screen and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi)
            @content
